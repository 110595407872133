jQuery(document).ready(function ($) {
  // Call the quoation scripts after page load
  setTimeout(function () {
    $.getScript("/wp-content/themes/tap/assets/js/min/quotation.js");
  }, 200);

  // Close TP Modal on Survey
  $(document).on("click", function (e) {
    if ($(e.target).is("[data-id='group-leave-review'] iframe") === false) {
      $("[data-id='group-leave-review']").css("display", "none");
    }
  });
  $("[data-id='group-leave-review']").on("click", function () {
    if ($(this).is(":visible")) {
      $("html, body").animate(
        {
          scrollTop: $("#form-submit").offset().top - 500,
        },
        "slow"
      );
    }
  });

  // console.log('here');

  if ("ontouchstart" in document.documentElement) {
    // Add a close toggle on touch devices
    $(".back-team-member-card").append('<div class="mobile-close">X</div>');

    $(".team-member").click(function () {
      $(this).find(".flip-container").addClass("is-flipped");
      return true;
    });

    $(".mobile-close").on("click", function (e) {
      e.stopPropagation();
      $(this).parent().parent().removeClass("is-flipped");
    });
  } else {
    $(".team-member").click(function () {
      $(this)
        .find(".flip-container")
        .addClass("is-flipped")
        .mouseleave(function () {
          $(this).removeClass("is-flipped");
        });
      return true;
    });
  }

  // Table of Contents
  //
  $("ul.toc-list").on("click", "a", function () {
    $(".toc li a").removeClass("link-active");
    $(this).addClass("link-active");
  });
  $("ul.toc-list").on("click", "li", function () {
    $("ul.toc-list li ul").removeClass("open");
    $("ul", this).toggleClass("open");
  });

  $(".bannerbox h3, .no-toc").each(function () {
    // Get the id of the current h3 element
    var id = $(this).attr("id");

    // Check if the id is not undefined or empty
    if (typeof id !== "undefined" && id !== "") {
      // Construct the selector for the corresponding li element in the TOC list
      var selector = 'ul.toc-list li a[href="#' + id + '"]';

      // Find the corresponding li element
      var $parentLi = $(selector).parent("li");

      console.log($parentLi);

      // Check if the parent li exists and has a subsequent ul sibling
      if ($parentLi.length) {
        // Find the child ul
        var $ul = $parentLi.children("ul");
        if ($ul.length) {
          // Move the ul into the previous li
          var $prevLi = $parentLi.prev("li");
          if ($prevLi.length) {
            var $newUl = $("<ul>").append($ul.children());
            $prevLi.append($newUl);
          }
        }
        // Remove the parent li
        $parentLi.remove();
      }
    }
  });

  // Scroll progress bar
  //
  const progress = document.querySelector(".progress-bar");
  const article = document.querySelector(".progress-wrapper");

  function updateProgressBar() {
    const articleHeight = article.clientHeight;
    const progressHeight = window.innerHeight;
    const scrollDistance = article.getBoundingClientRect().top;

    const scrolled =
      ((scrollDistance < 0 ? -scrollDistance : 0) /
        (articleHeight - progressHeight)) *
      100;
    progress.style.width = Math.min(100, Math.max(0, scrolled)) + "%";
  }

  if ($("body").hasClass("single-post")) {
    if (
      window.innerHeight - document.querySelector(".toc").clientHeight <=
      300
    ) {
      $(".toc").css("position", "relative").css("top", "40px");
      $(".progress").css("display", "none");
    }
    window.addEventListener("scroll", updateProgressBar);
    window.addEventListener("resize", updateProgressBar); // Ensure accuracy when the window is resized
    updateProgressBar(); // Initialize the progress bar on load
  }
});

// Sticky Header
document.addEventListener("DOMContentLoaded", function () {
  // When the event DOMContentLoaded occurs, it is safe to access the DOM

  // When the user scrolls the page, execute myFunction
  window.addEventListener("scroll", myFunctionForSticky);

  // Get the navbar
  var navbar = document.getElementById("myHeader");

  // Get the offset position of the navbar
  var sticky = navbar.offsetTop;
  var stickycta = document.getElementById("menu-item-349");
  var stickyheaderwidth = document.getElementById("stickywidth");
  var calltoday = document.getElementById("calltodayid");
  var calltodaynumber = document.getElementById("calltodaynumberid");

  // Logo switch
  var logomobileicon = document.getElementById("logomobileicon");
  var logomobile = document.getElementById("logomobile");

  // Mobile Nav
  var mobilenav = document.getElementById("mobilenav");

  // Add the sticky class to the navbar when you reach its scroll position.
  // Remove "sticky" when you leave the scroll position

  function myFunctionForSticky() {
    if (window.pageYOffset > sticky) {
      navbar.classList.add("sticky");
      stickycta.classList.add("cta-button");
      stickyheaderwidth.classList.add("stickyheaderwidth");
      calltoday.classList.add("call-today-green");
      calltodaynumber.classList.add("call-today-number-green");
      if (logomobileicon != null) {
        logomobileicon.classList.add("display-block-mobile-icon");
      }
      logomobile.classList.add("display-none-mobile");
      stickycta.classList.add("display-block-mobile");
      mobilenav.classList.add("mobile-nav-button-sticky");
    } else {
      navbar.classList.remove("sticky");
      stickycta.classList.remove("cta-button");
      stickyheaderwidth.classList.remove("stickyheaderwidth");
      calltoday.classList.remove("call-today-green");
      calltodaynumber.classList.remove("call-today-number-green");
      if (logomobileicon != null) {
        logomobileicon.classList.add("display-block-mobile-icon");
      }
      logomobile.classList.remove("display-none-mobile");
      stickycta.classList.remove("display-block-mobile");
      mobilenav.classList.remove("mobile-nav-button-sticky");
    }
  }
});

// Side Menu
jQuery(document).ready(function ($) {
  // $(".sidemenu-accordion").on("click", function () {
  //   if ($(this).hasClass("active")) {
  //     $(this).removeClass("active");
  //     $(this).next(".panel").removeClass("open");
  //   } else {
  //     $(".sidemenu-accordion").removeClass("active");
  //     $(".panel").removeClass("open");
  //     $(this).addClass("active");
  //     $(this).next(".panel").addClass("open");
  //   }
  // });

  $("#mySidenav .menu-toggle").on("click", function () {
    if (
      $(this).parent().hasClass("menu-item-has-children") &&
      $(this).parent().hasClass("active")
    ) {
      $(this).parent().removeClass("active");
      $(this).next(".sub-menu").removeClass("open");
    } else {
      $(".menu-item-has-children").removeClass("active");
      $(".sub-menu").removeClass("open");
      $(this).parent().addClass("active");
      $(this).next(".sub-menu").addClass("open");
    }
  });
});

// Blog Categories
jQuery(document).ready(function ($) {
  $(".category-expand").on("click", function () {
    if ($(this).hasClass("category-active")) {
      $(this).removeClass("category-active").text("Show All Categories");
      $(".category-panel").removeClass("open");
    } else {
      $(".category-expand")
        .addClass("category-active")
        .text("Collapse Categories");
      $(".category-panel").addClass("open");
    }
  });
});

// Tax Calculators
(function ($) {
  $(function () {
    var taxCalculatorFormSubmit = function (event) {
      event.preventDefault();
      var currentForm = event.currentTarget;
      $.post(
        $(currentForm).data("form-location"),
        $(currentForm).serialize(),
        function (data) {
          $(currentForm).closest(".tab-pane").empty().append(data);
          registerTaxCalculatorListeners();
        }
      );
    };

    var holidayFormSubmit = function (event) {
      event.preventDefault();
      var currentForm = event.currentTarget;
      var currentFormData = $(currentForm).serialize();
      $.get(
        $(currentForm).data("form-location"),
        $(currentForm).serialize(),
        function (data) {
          //console.log(data);
          //console.log(currentFormData);
          $(currentForm).closest(".tab-pane").empty().append(data);
          registerTaxCalculatorListeners();
        }
      ).fail(function () {
        //console.log('fail');
      });
    };

    var registerTaxCalculatorListeners = function () {
      $(".tax-calculator-form").submit(taxCalculatorFormSubmit);
      $(".holiday-form").submit(holidayFormSubmit);
    };

    var setSelectedTab = function () {
      var url = document.location.toString();
      if (url.match("#")) {
        activateTab($(".nav-pills a[href=#" + url.split("#")[1] + "]"));
      }
    };

    var activateTab = function (link) {
      $(link).parent().parent().find(".active").removeClass("active");
      $(link).parent().addClass("active");
      $(".tax-calculators .tab-content .active").removeClass("active");
      $(".tax-calculators .tab-content " + $(link).attr("href")).addClass(
        "active"
      );
      if ($(window).scrollTop() != 302) {
        $("body,html").animate(
          {
            scrollTop: "302px",
          },
          800
        );
      }
    };

    var taxCalculatorTabClick = function (event) {
      event.preventDefault();
      var href = $(event.currentTarget).attr("href");
      location.hash = href;
      activateTab(event.currentTarget);
    };

    $(".tax-calculators .nav-pills li a").click(taxCalculatorTabClick);

    if (
      !$("html").hasClass("post-single") &&
      !$("html").hasClass("post-vacancy")
    ) {
      registerTaxCalculatorListeners();
      setSelectedTab();
    }
  });
})(jQuery);
